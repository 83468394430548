import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React from "react";
import { Chart } from "react-google-charts";
import customizer from "./customizer";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function MentionedRelatives({ deceasedPerson, relatives, source, theme }) {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  let { palette } = theme;
  let { secondary } = palette;

  let mentionedRelatives = _.filter(relatives, function(o) {
    return o.type === "MENTIONED_RELATIVE" && o.results.length > 0;
  });

  let rows = [];

  mentionedRelatives.forEach(function(row) {
    let resultsObj = {};

    for (let i = 0; i < row.results.length; i++) {
      let resultItem = row.results[i];

      if (resultsObj[resultItem.address_id]) {
        resultsObj[resultItem.address_id] = [
          ...resultsObj[resultItem.address_id],
          resultItem
        ];
      } else {
        resultsObj[resultItem.address_id] = [resultItem];
      }
    }

    let newResults = [];

    for (var addressid in resultsObj) {
      if (resultsObj.hasOwnProperty(addressid)) {
        let resulArray = resultsObj[addressid];

        if (resulArray.length > 0) {
          let object = {};
          resulArray.forEach(resulArrayItem => {
            object = _.mergeWith(object, resulArrayItem, customizer);
          });
          newResults.push(object);
        }
      }
    }

    newResults.forEach(function(resultItem) {
      let rowPart = [
        `${row.details["FULL_NAME"]}${
          row.details["CITY"] ? `- ${row.details["CITY"]}` : ""
        }`
      ];

      let rsName = `${
        _.isArray(resultItem.initials)
          ? resultItem.initials
              .map(x => {
                if (resultItem.initials.length === 1) {
                  return `${x}`;
                } else {
                  return `"${x}"`;
                }
              })
              .join(", ")
          : resultItem.initials
      } ${resultItem.prefix ? resultItem.prefix : ""} ${
        _.isArray(resultItem.last_name)
          ? resultItem.last_name.join(", ")
          : resultItem.last_name
      }`;

      rowPart.push(
        `${rsName} - ${resultItem.zip_code} ${resultItem.house_number}`
      );

      rowPart.push(1);

      rows.push(rowPart);
    });
  });

  return (
    <>
      {mentionedRelatives.length > 0 ? (
        <Grid item xs={12}>
          <Paper style={{ padding: "10px" }} elevation={2}>
            {source ? (
              <div className="layout flex horizontal center-center">
                <Chip
                  variant="outlined"
                  color={source === "MENSENLINQ" ? "primary" : "secondary"}
                  label={
                    source === "MENSENLINQ" ? "mensenlinq.nl" : "postfilter.nl"
                  }
                />
              </div>
            ) : null}

            <Typography
              style={{ marginTop: "20px" }}
              variant="h6"
              color={source === "MENSENLINQ" ? "primary" : "secondary"}
              align="center"
              gutterBottom
            >
              Genoemde namen in Advertentie
            </Typography>

            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant={null}
              >
                <Tab label="Tabel" />
                <Tab label="Chart" />
              </Tabs>
            </AppBar>

            {value === 0 ? (
              <Card>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Genoemde naam</b>
                        </TableCell>

                        <TableCell>
                          <b>adres</b>
                        </TableCell>

                        <TableCell>
                          <b>Postcode</b>
                        </TableCell>

                        <TableCell>
                          <b>Stad</b>
                        </TableCell>

                        <TableCell>
                          <b>Telefoonnummer</b>
                        </TableCell>

                        <TableCell>
                          <b>link</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mentionedRelatives.map((row, ix) => {
                        let resultsObj = {};

                        for (let i = 0; i < row.results.length; i++) {
                          let resultItem = row.results[i];

                          if (resultsObj[resultItem.address_id]) {
                            resultsObj[resultItem.address_id] = [
                              ...resultsObj[resultItem.address_id],
                              resultItem
                            ];
                          } else {
                            resultsObj[resultItem.address_id] = [resultItem];
                          }
                        }

                        let newResults = [];

                        for (var addressid in resultsObj) {
                          if (resultsObj.hasOwnProperty(addressid)) {
                            let resulArray = resultsObj[addressid];

                            if (resulArray.length > 0) {
                              let object = {};
                              resulArray.forEach(resulArrayItem => {
                                object = _.mergeWith(
                                  object,
                                  resulArrayItem,
                                  customizer
                                );
                              });
                              newResults.push(object);
                            }
                          }
                        }

                        return (
                          <React.Fragment key={"mentionedRelatives" + ix}>
                            <TableRow
                              style={{
                                border: "2px solid grey",
                                background: "#f5f5f5"
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.details["FULL_NAME"]}
                              </TableCell>

                              <TableCell />

                              <TableCell />
                              <TableCell component="th" scope="row">
                                {row.details["CITY"]}
                              </TableCell>
                              <TableCell />

                              <TableCell />
                            </TableRow>

                            {newResults.map((resultItem, ixx) => (
                              <React.Fragment key={"results" + ixx}>
                                <TableRow style={{}}>
                                  <TableCell component="th" scope="row">
                                    {`${
                                      _.isArray(resultItem.initials)
                                        ? resultItem.initials
                                            .map(x => {
                                              if (
                                                resultItem.initials.length === 1
                                              ) {
                                                return `${x}`;
                                              } else {
                                                return `"${x}"`;
                                              }
                                            })
                                            .join(", ")
                                        : resultItem.initials
                                    } ${
                                      resultItem.prefix ? resultItem.prefix : ""
                                    } ${
                                      _.isArray(resultItem.last_name)
                                        ? resultItem.last_name.join(", ")
                                        : resultItem.last_name
                                    }`}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {resultItem.street}{" "}
                                    {resultItem.house_number}
                                  </TableCell>

                                  <TableCell component="th" scope="row">
                                    {resultItem.zip_code}
                                  </TableCell>

                                  <TableCell component="th" scope="row">
                                    {_.isArray(resultItem.city)
                                      ? resultItem.city.join(", ")
                                      : resultItem.city}
                                  </TableCell>

                                  <TableCell component="th" scope="row">
                                    {_.isArray(resultItem.full_landline_number)
                                      ? resultItem.full_landline_number.join(
                                          ", "
                                        )
                                      : resultItem.full_landline_number}
                                  </TableCell>

                                  <TableCell>
                                    {_.isArray(resultItem.url) ? (
                                      resultItem.url.map((urlItem, uxx) => (
                                        <Link
                                          color={
                                            source === "MENSENLINQ"
                                              ? "primary"
                                              : "secondary"
                                          }
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          key={"url" + uxx}
                                          style={{ marginRight: "10px" }}
                                          href={urlItem}
                                        >
                                          {urlItem.indexOf("detelefoongids") >
                                          -1
                                            ? "detelefoongids"
                                            : "telefoonboek"}
                                        </Link>
                                      ))
                                    ) : (
                                      <Link
                                        color={
                                          source === "MENSENLINQ"
                                            ? "primary"
                                            : "secondary"
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={resultItem.url}
                                      >
                                        {resultItem.url.indexOf(
                                          "detelefoongids"
                                        ) > -1
                                          ? "detelefoongids"
                                          : "telefoonboek"}
                                      </Link>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            ) : null}

            {value === 1 ? (
              <Card>
                <CardContent>
                  <div style={{ height: "1000px" }}>
                    <Chart
                      chartType="Sankey"
                      options={{ allowHtml: true }}
                      rows={rows}
                      width="100%"
                      height="1000px"
                      columns={[
                        {
                          type: "string",
                          label: "From"
                        },
                        {
                          type: "string",
                          label: "To"
                        },

                        {
                          type: "number",
                          label: "Weight"
                        }
                      ]}
                      options={{
                        legend: "none",
                        enableInteractivity: false,

                        tooltip: {
                          trigger: "none",
                          isHtml: "true"
                        },
                        sankey: {
                          node: { nodePadding: 20 }
                        }
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
            ) : null}
          </Paper>
        </Grid>
      ) : null}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(MentionedRelatives);
