import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React from "react";

function Header({ deceasedPerson }) {
  if (_.isArray(deceasedPerson)) {
    let deceasedPersonMensenlinq = _.find(deceasedPerson, function(o) {
      return o.source === "www.mensenlinq.nl";
    });

    let postfilterDeceasedPerson = _.find(deceasedPerson, function(o) {
      return o.source === "www.postfilter.nl";
    });

    return (
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" align="center">
          {deceasedPersonMensenlinq.full_name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="subheading"
          align="center"
          gutterBottom
        >
          {postfilterDeceasedPerson.full_name}
        </Typography>
        <div className="layout flex horizontal center-center">
          <Typography variant="subtitle1" align="center" gutterBottom>
            {`${
              deceasedPersonMensenlinq.date_of_birth
                ? deceasedPersonMensenlinq.date_of_birth
                : deceasedPersonMensenlinq.ad_ocr_date_of_birth_range
            }`}
          </Typography>
          <Typography
            style={{ margin: "10px" }}
            color="textSecondary"
            variant="subtitle1"
            align="center"
            gutterBottom
          >
            –
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            {`${deceasedPersonMensenlinq.date_of_death}`}
          </Typography>
        </div>
      </Grid>
    );
  } else {
    let {
      full_name,
      date_of_birth,
      date_of_death,
      ad_ocr_date_of_birth_range
    } = deceasedPerson;

    return (
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {full_name}
        </Typography>
        <div className="layout flex horizontal center-center">
          <Typography variant="subtitle1" align="center" gutterBottom>
            {`${date_of_birth ? date_of_birth : ad_ocr_date_of_birth_range}`}
          </Typography>
          <Typography
            style={{ margin: "10px" }}
            color="textSecondary"
            variant="subtitle1"
            align="center"
            gutterBottom
          >
            –
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            {`${date_of_death}`}
          </Typography>
        </div>
      </Grid>
    );
  }
}

export default Header;
