import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React from "react";
import customizer from "./customizer";

function CorrespondenceAddress({ deceasedPerson, relatives, source }) {
  let {
    last_name,
    ad_ocr_contact_address_street,
    ad_ocr_contact_address_house_number,
    ad_ocr_contact_address_zipcode,
    ad_ocr_contact_address_city_name
  } = deceasedPerson;

  let corresObject = _.find(relatives, function(o) {
    return o.type === "CORRESPONDENCE_ADDRESS" && o.results.length > 0;
  });

  let correspondentieAddress = _.filter(relatives, function(o) {
    if (corresObject) {
      return o.type === "CORRESPONDENCE_ADDRESS";
    } else {
      return o.type === "CORRESPONDENCE_ADDRESS_ID";
    }
  });

  return (
    <>
      {correspondentieAddress.length > 0 ? (
        <Grid item xs={12}>
          <Paper style={{ padding: "10px" }} elevation={2}>
            {source ? (
              <div className="layout flex horizontal center-center">
                <Chip
                  variant="outlined"
                  color={source === "MENSENLINQ" ? "primary" : "secondary"}
                  label={
                    source === "MENSENLINQ" ? "mensenlinq.nl" : "postfilter.nl"
                  }
                />
              </div>
            ) : null}

            <Typography
              style={{ marginTop: "20px" }}
              variant="h6"
              color={source === "MENSENLINQ" ? "primary" : "secondary"}
              align="center"
              gutterBottom
            >
              {corresObject
                ? "Correspondentieadres & Achternaam"
                : "Correspondentieadres (geen achternaam match)"}
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Genoemde naam</b>
                  </TableCell>

                  <TableCell>
                    <b>adres</b>
                  </TableCell>

                  <TableCell>
                    <b>Postcode</b>
                  </TableCell>

                  <TableCell>
                    <b>Stad</b>
                  </TableCell>

                  <TableCell>
                    <b>Telefoonnummer</b>
                  </TableCell>

                  <TableCell>
                    <b>link</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {correspondentieAddress.map((row, ix) => {
                  let resultsObj = {};

                  for (let i = 0; i < row.results.length; i++) {
                    let resultItem = row.results[i];

                    if (resultsObj[resultItem.address_id]) {
                      resultsObj[resultItem.address_id] = [
                        ...resultsObj[resultItem.address_id],
                        resultItem
                      ];
                    } else {
                      resultsObj[resultItem.address_id] = [resultItem];
                    }
                  }

                  let newResults = [];

                  for (var addressid in resultsObj) {
                    if (resultsObj.hasOwnProperty(addressid)) {
                      let resulArray = resultsObj[addressid];

                      if (resulArray.length > 0) {
                        let object = {};
                        resulArray.forEach(resulArrayItem => {
                          object = _.mergeWith(
                            object,
                            resulArrayItem,
                            customizer
                          );
                        });
                        newResults.push(object);
                      }
                    }
                  }

                  return (
                    <React.Fragment key={"correspondentieAddress" + ix}>
                      <TableRow
                        style={{
                          border: "2px solid grey",
                          background: "#f5f5f5"
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <span style={{ color: "#9e9e9e" }}>
                            {last_name} (aangenomen)
                          </span>
                        </TableCell>

                        <TableCell>
                          {ad_ocr_contact_address_street}{" "}
                          {ad_ocr_contact_address_house_number}
                        </TableCell>

                        <TableCell>{ad_ocr_contact_address_zipcode}</TableCell>
                        <TableCell>
                          {ad_ocr_contact_address_city_name}
                        </TableCell>
                        <TableCell />

                        <TableCell />
                      </TableRow>

                      {newResults.map((resultItem, ixx) => (
                        <React.Fragment key={"results" + ixx}>
                          <TableRow
                            style={{
                              border: "2px solid #81c784",
                              background: "#e8f5e9"
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {`${
                                _.isArray(resultItem.initials)
                                  ? resultItem.initials
                                      .map(x => {
                                        return `"${x}"`;
                                      })
                                      .join(", ")
                                  : resultItem.initials
                              } ${resultItem.prefix ? resultItem.prefix : ""} ${
                                _.isArray(resultItem.last_name)
                                  ? resultItem.last_name.join(", ")
                                  : resultItem.last_name
                              }`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {resultItem.street} {resultItem.house_number}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {resultItem.zip_code}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {_.isArray(resultItem.city)
                                ? resultItem.city.join(", ")
                                : resultItem.city}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {_.isArray(resultItem.full_landline_number)
                                ? resultItem.full_landline_number.join(", ")
                                : resultItem.full_landline_number}
                            </TableCell>

                            <TableCell>
                              {_.isArray(resultItem.url) ? (
                                resultItem.url.map((urlItem, uxx) => (
                                  <Link
                                    color={
                                      source === "MENSENLINQ"
                                        ? "primary"
                                        : "secondary"
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    key={"url" + uxx}
                                    style={{ marginRight: "10px" }}
                                    href={urlItem}
                                  >
                                    {urlItem.indexOf("detelefoongids") > -1
                                      ? "detelefoongids"
                                      : "telefoonboek"}
                                  </Link>
                                ))
                              ) : (
                                <Link
                                  color={
                                    source === "MENSENLINQ"
                                      ? "primary"
                                      : "secondary"
                                  }
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={resultItem.url}
                                >
                                  {resultItem.url.indexOf("detelefoongids") > -1
                                    ? "detelefoongids"
                                    : "telefoonboek"}
                                </Link>
                              )}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      ) : null}
    </>
  );
}

export default CorrespondenceAddress;
