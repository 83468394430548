import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React from "react";

function DeceasedPersonOverview({ deceasedPerson }) {
  let mensenlinqDeceasedPerson = _.find(deceasedPerson, function(o) {
    return o.source === "www.mensenlinq.nl";
  });

  return (
    <Grid
      item
      xs={
        mensenlinqDeceasedPerson && mensenlinqDeceasedPerson.ad_image_urls
          ? 8
          : 12
      }
    >
      <Paper style={{ height: "100%" }} elevation={2}>
        <Table
          style={{
            border: "2px solid grey",
            background: "#f5f5f5"
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {deceasedPerson.map((o, index) => {
                return (
                  <TableCell key={index}>
                    <Chip
                      variant="outlined"
                      color={
                        o.source.indexOf("mensenlinq") > -1
                          ? "primary"
                          : "secondary"
                      }
                      label={o.source.replace("www.", "")}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Volledige naam</b>
              </TableCell>
              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.full_name}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Initialen</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.initials}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Voornaam</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.first_name}</TableCell>;
              })}
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Achternaam</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.last_name}</TableCell>;
              })}
            </TableRow>

            <TableRow>
              <TableCell>
                <b>
                  {deceasedPerson[0].date_of_birth
                    ? "Geboortedatum"
                    : "Geboortejaar"}
                </b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return (
                  <TableCell key={index}>
                    {" "}
                    {o.date_of_birth
                      ? o.date_of_birth
                      : o.ad_ocr_date_of_birth_range}
                  </TableCell>
                );
              })}
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Geboorteplaats</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.place_of_birth}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Overlijdensdatum</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.date_of_death}</TableCell>;
              })}
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Plaats van overlijden</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.place_of_death}</TableCell>;
              })}
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Leeftijd van overlijden</b>
              </TableCell>

              {deceasedPerson.map((o, index) => {
                return <TableCell key={index}>{o.death_age}</TableCell>;
              })}
            </TableRow>

            {mensenlinqDeceasedPerson &&
            mensenlinqDeceasedPerson.ad_ocr_all_emails ? (
              <TableRow>
                <TableCell>
                  <b>Email adressen</b>
                </TableCell>

                {deceasedPerson.map((o, index) => {
                  return (
                    <TableCell key={index}>{o.ad_ocr_all_emails}</TableCell>
                  );
                })}
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
}

export default DeceasedPersonOverview;
