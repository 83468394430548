import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CorrespondenceAddress from "./CorrespondenceAddress";
import DeceasedPersonImage from "./DeceasedPersonImage";
import DeceasedPersonOverview from "./DeceasedPersonOverview";
import Header from "./Header";
import MentionedRelatives from "./MentionedRelatives";
import Partner from "./Partner";

let widthS = 1200;

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: { main: "#a54399" },
    secondary: { main: "#009CDE" }
  }
});

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(widthS + theme.spacing.unit * 2 * 2)]: {
      width: widthS,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    background: "none",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(widthS + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  }
});

function App({ classes, obituariesId }) {
  const [data, setData] = useState({ deceased_person: {}, relatives: [] });

  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        `https://ooohvok06j.execute-api.eu-west-1.amazonaws.com/prod/data?id=${obituariesId}`
      );

      setData(result.data);
    }
    fetchData();
  }, [obituariesId]); // Or [] if effect doesn't need props or state

  let hasMultipleCodes = _.isArray(data);

  if (hasMultipleCodes === false) {
    if (!data.deceased_person.full_name) {
      return null;
    }
  }

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper elevation={0} className={classes.paper}>
            <Grid container spacing={40} alignItems="stretch">
              <Header
                deceasedPerson={
                  hasMultipleCodes === false
                    ? data.deceased_person
                    : data.map(o => {
                        return o.deceased_person;
                      })
                }
              />

              <DeceasedPersonOverview
                deceasedPerson={
                  hasMultipleCodes === false
                    ? [data.deceased_person]
                    : data.map(o => {
                        return o.deceased_person;
                      })
                }
              />

              <DeceasedPersonImage
                deceasedPerson={
                  hasMultipleCodes === false
                    ? [data.deceased_person]
                    : data.map(o => {
                        return o.deceased_person;
                      })
                }
              />

              {hasMultipleCodes === false ? (
                <CorrespondenceAddress
                  source={data.source}
                  deceasedPerson={data.deceased_person}
                  relatives={data.relatives}
                />
              ) : (
                <>
                  {data.map((o, index) => {
                    return (
                      <CorrespondenceAddress
                        source={o.source}
                        key={index}
                        deceasedPerson={o.deceased_person}
                        relatives={o.relatives}
                      />
                    );
                  })}
                </>
              )}

              {hasMultipleCodes === false ? (
                <Partner
                  source={data.source}
                  deceasedPerson={data.deceased_person}
                  relatives={data.relatives}
                />
              ) : (
                <>
                  {data.map((o, index) => {
                    return (
                      <Partner
                        source={o.source}
                        key={index}
                        deceasedPerson={o.deceased_person}
                        relatives={o.relatives}
                      />
                    );
                  })}
                </>
              )}

              {hasMultipleCodes === false ? (
                <MentionedRelatives
                  source={data.source}
                  deceasedPerson={data.deceased_person}
                  relatives={data.relatives}
                />
              ) : (
                <>
                  {data.map((o, index) => {
                    return (
                      <MentionedRelatives
                        source={o.source}
                        key={index}
                        deceasedPerson={o.deceased_person}
                        relatives={o.relatives}
                      />
                    );
                  })}
                </>
              )}
            </Grid>
          </Paper>
        </main>
      </MuiThemeProvider>
    </>
  );
}

export default withStyles(styles)(App);
