import _ from "lodash";

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    if (
      (typeof objValue[0] === "string" || objValue[0] instanceof String) &&
      objValue[0].startsWith("http")
    ) {
      return [...new Set(objValue.concat(srcValue))];
    } else {
      return [...new Set(objValue.concat(srcValue).filter(Boolean))];
    }
  } else if (objValue === srcValue) {
    return objValue;
  } else {
    if (
      (typeof objValue === "string" || objValue instanceof String) &&
      objValue.startsWith("http")
    ) {
      return [objValue, srcValue];
    } else {
      return [objValue, srcValue].filter(Boolean);
    }
  }
}

export default customizer;
