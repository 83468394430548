import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import OpenInNew from "@material-ui/icons/Fullscreen";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import _ from "lodash";
import React from "react";
import SwipeableViews from "react-swipeable-views";

const styles = theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default
  },
  img: {
    maxHeight: "590px",

    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "100%"
  }
});

function DeceasedPersonImage({
  deceasedPerson: deceasedPersonArray,
  theme,
  classes
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  let deceasedPerson = _.find(deceasedPersonArray, function(o) {
    return o.source === "www.mensenlinq.nl";
  });

  if (!deceasedPerson) {
    return null;
  }

  if (!deceasedPerson && !deceasedPerson.ad_image_urls) {
    return null;
  }

  const maxSteps = deceasedPerson.ad_image_urls.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <>
      <Grid item xs={4}>
        <Paper
          className="layout flex  vertical center-center"
          style={{ overflow: "hidden" }}
          elevation={2}
        >
          <div
            style={{ padding: "12px" }}
            className="layout flex  horizontal center-center"
          >
            <Chip variant="outlined" color="primary" label={"mensenlinq.nl"} />
          </div>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {deceasedPerson.ad_image_urls.map((step, index) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <div className="layout flex vertical center-center">
                    <img
                      style={{
                        maxWidth: "300px",
                        maxHeight: "590px",
                        marginBottom: "10px"
                      }}
                      src={step}
                      alt={""}
                    />
                    <Button
                      onClick={() => {
                        window.open(
                          step,
                          "mensenlinq",
                          "width=800,height=825,scrollbars=no,toolbar=no,location=no"
                        );
                        return false;
                      }}
                    >
                      <OpenInNew />
                    </Button>
                  </div>
                ) : null}
              </div>
            ))}
          </SwipeableViews>
          <div
            style={{ width: "100%" }}
            className="layout flex vertical center-center"
          >
            <MobileStepper
              style={{ width: "100%", margin: "0px 10px" }}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Volgende
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Vorige
                </Button>
              }
            />
          </div>
        </Paper>
      </Grid>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(DeceasedPersonImage);
